<template>
  <v-container class="pedido-realizado-container">
      <v-row class="pedido-realizado-row">
               <v-col style="text-align:center;" class="pedidoRealizado-columna">
               <h1 class="pedido-realizado-titulo"> {{ $t('pedidoRealizado.titulo') }}</h1>
               <p class="pedido-realizado-primer-parrafo">{{ $t('pedidoRealizado.parrafo1') }}</p>
               <p>{{ $t('pedidoRealizado.parrafo2') }}</p>
               <p>{{ $t('pedidoRealizado.parrafo3.parte1') }} {{ $t('pedidoRealizado.parrafo3.parte2') }}</p>
               <p>{{ $t('pedidoRealizado.parrafo4') }}</p>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
export default {
    components: { }
}
</script>
